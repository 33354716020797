export function getBigGoal(monthlyGoal: number, monthlyInterestRate: number) {
  return monthlyGoal / monthlyInterestRate;
}

function getInitialValueCompound(
  initialAmount: number,
  monthlyInterestRate: number,
  periodInMonths: number
) {
  return initialAmount === 0
    ? 0
    : initialAmount * Math.pow(1 + monthlyInterestRate, periodInMonths);
}

function getValueCompoundWithMonthlyDeposit(
  monthlyDeposit: number,
  monthlyInterestRate: number,
  periodInMonths: number
) {
  return (
    (monthlyDeposit * (Math.pow(1 + monthlyInterestRate, periodInMonths) - 1)) /
    monthlyInterestRate
  );
}

function percentageToDecimal(value: number) {
  return value / 100;
}

export function getMonthlyDeposit(
  initialAmount: number,
  monthlyGoal: number,
  monthlyInterestRatePercentage: number,
  periodInMonths: number
) {
  const monthlyInterestRate = percentageToDecimal(
    monthlyInterestRatePercentage
  );
  const bigGoal = getBigGoal(monthlyGoal, monthlyInterestRate);
  const initialValueCompound = getInitialValueCompound(
    initialAmount,
    monthlyInterestRate,
    periodInMonths
  );
  const partialFactor = Math.pow(1 + monthlyInterestRate, periodInMonths) - 1;
  return (
    ((bigGoal - initialValueCompound) * monthlyInterestRate) / partialFactor
  );
}

export function getPeriodInMonths(
  initialAmount: number,
  monthlyGoal: number,
  monthlyDeposit: number,
  monthlyInterestRatePercentage: number
) {
  const monthlyInterestRate = percentageToDecimal(
    monthlyInterestRatePercentage
  );
  const bigGoal = getBigGoal(monthlyGoal, monthlyInterestRate);
  let periodInMonths = 0;
  let sum = 0;
  const maxIterations = 1200;
  let i = 0;
  while (bigGoal >= sum && i < maxIterations) {
    i++;
    periodInMonths++;
    const part1 = getInitialValueCompound(
      initialAmount,
      monthlyInterestRate,
      periodInMonths
    );
    const part2 = getValueCompoundWithMonthlyDeposit(
      monthlyDeposit,
      monthlyInterestRate,
      periodInMonths
    );
    sum = part1 + part2;
  }

  return periodInMonths;
}

export function getMonthlyInterestRate(annualInterest: number) {
  return (Math.pow(1 + percentageToDecimal(annualInterest), 1 / 12) - 1) * 100;
}

export function getAnnualInterestRate(monthlyInterest: number) {
  return (Math.pow(1 + percentageToDecimal(monthlyInterest), 12) - 1) * 100;
}
