import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import {
  getAnnualInterestRate,
  getBigGoal,
  getMonthlyInterestRate,
} from '../../lib/formulas';
import { currencyFormatter, RATE_TYPES } from '../../utils/constants';
import { BaseFormStateType } from '../BaseForm/BaseForm';
import InfoRounded from '@mui/icons-material/InfoRounded';

type ProjectionGridProps = {
  baseFormProps: BaseFormStateType;
};

export default function ProjectionGrid({ baseFormProps }: ProjectionGridProps) {
  let yearlyInterestRate = baseFormProps.yield_averageInvestment;
  let monthlyInterestRate = baseFormProps.yield_averageInvestment;
  if (baseFormProps.yield_rateType === RATE_TYPES.MONTHLY) {
    yearlyInterestRate = getAnnualInterestRate(
      baseFormProps.yield_averageInvestment
    );
  } else {
    monthlyInterestRate = getMonthlyInterestRate(
      baseFormProps.yield_averageInvestment
    );
  }

  const getProjectionValues = (row: number): (number | string)[] => {
    const desiredIncome =
      baseFormProps.passiveIncome_desiredMonthly * (1 + row * 0.2);
    const bigGoalDefault = getBigGoal(desiredIncome, monthlyInterestRate / 100);
    const bigGoalPlusOne = getBigGoal(
      desiredIncome,
      getMonthlyInterestRate(yearlyInterestRate + 1) / 100
    );
    const bigGoalPlusTwo = getBigGoal(
      desiredIncome,
      getMonthlyInterestRate(yearlyInterestRate + 2) / 100
    );

    return [
      currencyFormatter.format(desiredIncome),
      currencyFormatter.format(bigGoalDefault),
      currencyFormatter.format(bigGoalPlusOne),
      currencyFormatter.format(bigGoalPlusTwo),
    ];
  };

  const renderTableRows = () => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < 3; i++) {
      const rowData = getProjectionValues(i);
      rows.push(
        <TableRow key={i}>
          <TableCell align="center">{rowData[0]}</TableCell>
          <TableCell
            align="center"
            style={{ backgroundColor: i === 0 ? '#fcaf53' : 'white' }}
          >
            {rowData[1]}
          </TableCell>
          <TableCell align="center">{rowData[2]}</TableCell>
          <TableCell align="center">{rowData[3]}</TableCell>
        </TableRow>
      );
    }
    return rows;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Check different projections
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Passive Income</TableCell>
                <TableCell align="center">{`${yearlyInterestRate.toFixed(
                  2
                )}% Annual`}</TableCell>
                <TableCell align="center">{`${(yearlyInterestRate + 1).toFixed(
                  2
                )}% Annual`}</TableCell>
                <TableCell align="center">{`${(yearlyInterestRate + 2).toFixed(
                  2
                )}% Annual`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          align="justify"
          p={2}
          pt={0}
          display="inline-block"
        >
          <InfoRounded fontSize="inherit" style={{ marginRight: 8 }} />
          This is how much money you'd need to have (your "Big Goal") to achieve your projected passive
          income.
        </Typography>
      </Grid>
    </Grid>
  );
}
