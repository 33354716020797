export const MINIMUM_WAGE_BR = 1212.0; // R$ 1.212,00
export enum RATE_TYPES {
  YEARLY,
  MONTHLY,
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
