import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { MINIMUM_WAGE_BR } from '../../utils/constants';
import { get, set } from 'local-storage';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { FormControl, Grid } from '@mui/material';
import { Box } from '@mui/system';

const LS_CALC_STATE = 'sgf_calc_state';

export type SavingsGoalFormStateType = {
  savings_monthly: number;
};

const defaultDateGoalFormState: SavingsGoalFormStateType = {
  // With this we can calculate how much you should actually save to reach your goal
  savings_monthly: MINIMUM_WAGE_BR * 0.2, // 20%
};

const getInitialFormState = (): SavingsGoalFormStateType => {
  // checks if there's something stored in LS. if not, use the default
  const storedState = get<SavingsGoalFormStateType>(LS_CALC_STATE);

  if (storedState) {
    const storedKeys = Object.keys(storedState).sort().join();
    const expectedKeys = Object.keys(defaultDateGoalFormState).sort().join();
    if (storedKeys !== expectedKeys) {
      // Must clear whatever was stored, it's an older version...
      set(LS_CALC_STATE, defaultDateGoalFormState);
    } else {
      return storedState;
    }
  }

  return defaultDateGoalFormState;
};

type FormParams = {
  onFormChange: (newFormData: SavingsGoalFormStateType) => void;
};

export default function Form({ onFormChange }: FormParams) {
  const [formData, setFormData] = useState(getInitialFormState());

  useEffect(() => {
    set(LS_CALC_STATE, formData);
    onFormChange(formData);
  }, [formData, onFormChange]);

  const handleSavingsMonthlyChange = useCallback(
    (value: number) => {
      setFormData({
        ...formData,
        savings_monthly: value,
      });
    },
    [formData]
  );

  return (
    <Box padding={2}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={8}>
          <label>How much money can/do you save each month?</label>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <CurrencyTextField
              id="savings-monthly-field"
              value={formData.savings_monthly}
              currencySymbol="$"
              minimumValue="0"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(_: any, value: number) => {
                handleSavingsMonthlyChange(value);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
