import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  getBigGoal,
  getMonthlyDeposit,
  getMonthlyInterestRate,
  getPeriodInMonths,
} from '../../lib/formulas';
import { currencyFormatter, RATE_TYPES } from '../../utils/constants';
import BaseForm, { BaseFormStateType } from '../BaseForm/BaseForm';
import DateGoalForm, {
  DateGoalFormStateType,
} from '../DateGoalForm/DateGoalForm';
import ProjectionGrid from '../ProjectionGrid/ProjectionGrid';
import SavingsGoalForm, {
  SavingsGoalFormStateType,
} from '../SavingsGoalForm/SavingsGoalForm';

import './App.css';

export type SimulationResults = {
  monthlyDeposits: number;
  monthsUntilFreedom: number;
};

function App() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [baseFormData, setBaseFormData] = useState<BaseFormStateType>();
  const [dateGoalFormData, setDateGoalFormData] =
    useState<DateGoalFormStateType>();
  const [savingsGoalFormData, setSavingsGoalFormData] =
    useState<SavingsGoalFormStateType>();
  const [selectedTab, setSelectedTab] = useState(0);

  const [simulationResults, setSimulationResults] = useState<SimulationResults>(
    {
      monthlyDeposits: 0,
      monthsUntilFreedom: Infinity,
    }
  );

  const handleBaseFormChange = (newFormData: BaseFormStateType) => {
    setBaseFormData(newFormData);
  };

  const handleDateGoalFormChange = (newFormData: DateGoalFormStateType) => {
    setDateGoalFormData(newFormData);
  };

  const handleSavingsGoalFormChange = (
    newFormData: SavingsGoalFormStateType
  ) => {
    setSavingsGoalFormData(newFormData);
  };

  const handleTabChange = (tabId: number) => {
    setSelectedTab(tabId);
  };

  const depositSummary = (
    bigGoal: string,
    year: number,
    yearlyRate: string,
    monthlyInvestment: string
  ): JSX.Element => {
    return (
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="warning" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            With a <b>{yearlyRate}%</b> yield
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            To achieve your Big Goal of {bigGoal}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            You need to invest {monthlyInvestment} monthly
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Until January 1st, {year}</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
          </TimelineSeparator>
          <TimelineContent>
            <a
              href="https://ae.studio/join-us"
              target="_blank"
              rel="noreferrer"
            >
              Or join us for a much quicker journey with a fat equity plan! 🙂
            </a>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  };

  const deadlineSummary = (
    bigGoal: string,
    monthsToInvest: string,
    yearlyRate: string,
    monthlyInvestment: string
  ): JSX.Element => {
    const now = new Date();
    const endDate = new Date(now.setMonth(now.getMonth() + +monthsToInvest));
    const endDateCorrected = new Date(endDate.setDate(1));
    return (
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="warning" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>With a {yearlyRate}% yield</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            To achieve your Big Goal of {bigGoal}
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            And investing {monthlyInvestment} each month
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            It will take you {monthsToInvest} months to reach your goals (By{' '}
            {endDateCorrected.toLocaleDateString('en')})
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
          </TimelineSeparator>
          <TimelineContent>
            <a
              href="https://ae.studio/join-us"
              target="_blank"
              rel="noreferrer"
            >
              Or join us for a much quicker journey with a fat equity plan! 🙂
            </a>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  };

  const getTimeDiffInMonths = useCallback(() => {
    const januaryOfYear = new Date(
      dateGoalFormData?.retirement_year ?? new Date().getFullYear() + 1,
      0,
      1,
      0,
      0,
      0,
      0
    );
    const timeDiffInMs = januaryOfYear.getTime() - new Date().getTime();
    return Math.ceil(timeDiffInMs / 1000 / 60 / 60 / 24 / 30);
  }, [dateGoalFormData]);

  useEffect(() => {
    const timeDiffInMonths = getTimeDiffInMonths();

    const monthlyDeposits = getMonthlyDeposit(
      baseFormData?.initialAmount ?? 0,
      baseFormData?.passiveIncome_desiredMonthly ?? 0,
      baseFormData?.yield_rateType === RATE_TYPES.YEARLY
        ? getMonthlyInterestRate(baseFormData?.yield_averageInvestment ?? 0)
        : baseFormData?.yield_averageInvestment ?? 0,
      timeDiffInMonths
    );

    const monthsUntilFreedom = getPeriodInMonths(
      baseFormData?.initialAmount ?? 0,
      baseFormData?.passiveIncome_desiredMonthly ?? 0,
      savingsGoalFormData?.savings_monthly ?? 0,
      baseFormData?.yield_rateType === RATE_TYPES.YEARLY
        ? getMonthlyInterestRate(baseFormData?.yield_averageInvestment ?? 0)
        : baseFormData?.yield_averageInvestment ?? 0
    );

    setSimulationResults({
      monthlyDeposits: monthlyDeposits,
      monthsUntilFreedom: monthsUntilFreedom,
    });
  }, [
    baseFormData,
    dateGoalFormData,
    getTimeDiffInMonths,
    savingsGoalFormData,
  ]);

  const bigGoalCalculated = getBigGoal(
    baseFormData?.passiveIncome_desiredMonthly ?? 0,
    (baseFormData?.yield_rateType === RATE_TYPES.YEARLY
      ? getMonthlyInterestRate(baseFormData?.yield_averageInvestment ?? 0)
      : baseFormData?.yield_averageInvestment ?? 0) / 100
  );

  return (
    <div className="App">
      <header className="App__header">
        <Box mt={2}>
          <Typography variant={matches ? 'h4' : 'h5'} align="center">
            Passive Income Calculator
          </Typography>
        </Box>
      </header>
      <main className="App__main-section">
        <Grid container p={4} spacing={2}>
          <Grid item xs={12} sm={6} mb={2}>
            <Paper elevation={1}>
              <BaseForm onFormChange={handleBaseFormChange} />
            </Paper>
          </Grid>
          {baseFormData && (
            <Grid item xs={12} sm={6}>
              <Paper elevation={1}>
                <ProjectionGrid baseFormProps={baseFormData} />
              </Paper>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid item container xs={12} sm={8} ml="auto" mr="auto">
              <Grid item xs={12}>
                <Typography variant="h5" align="center" mt={2}>
                  How would you like to do the estimation?
                </Typography>
                <Tabs
                  value={selectedTab}
                  onChange={(_, value: number) => handleTabChange(value)}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  <Tab label="By Date" />
                  <Tab label="By a monthly deposit" />
                </Tabs>
              </Grid>

              {selectedTab === 0 && (
                <Grid item xs={12} width="100%">
                  <DateGoalForm onFormChange={handleDateGoalFormChange} />
                </Grid>
              )}
              {selectedTab === 1 && (
                <Grid item xs={12} width="100%">
                  <SavingsGoalForm onFormChange={handleSavingsGoalFormChange} />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} mt={2}>
              {selectedTab === 0
                ? depositSummary(
                    currencyFormatter.format(bigGoalCalculated),
                    dateGoalFormData?.retirement_year ?? 0,
                    `${(baseFormData?.yield_averageInvestment ?? 0).toFixed(
                      2
                    )}`,
                    `${currencyFormatter.format(
                      simulationResults.monthlyDeposits
                    )}`
                  )
                : deadlineSummary(
                    currencyFormatter.format(bigGoalCalculated),
                    `${simulationResults.monthsUntilFreedom}`,
                    `${(baseFormData?.yield_averageInvestment ?? 0).toFixed(
                      2
                    )}`,
                    currencyFormatter.format(
                      savingsGoalFormData?.savings_monthly ?? 0
                    )
                  )}
            </Grid>
          </Grid>
        </Grid>
      </main>
      <footer
        className="App__footer"
        style={{ textAlign: 'center', padding: '0 0 16px' }}
      >
        <a
          href="https://ae.studio/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          Made with ❤️ by AE Studio
        </a>
      </footer>
    </div>
  );
}

export default App;
