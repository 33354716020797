import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { MINIMUM_WAGE_BR, RATE_TYPES } from '../../utils/constants';
import { get, set } from 'local-storage';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  getAnnualInterestRate,
  getMonthlyInterestRate,
} from '../../lib/formulas';

const LS_CALC_STATE = 'bf_calc_state';

export type BaseFormStateType = {
  passiveIncome_desiredMonthly: number;
  initialAmount: number;
  yield_averageInvestment: number;
  yield_rateType: RATE_TYPES;
};

const defaultBaseFormState: BaseFormStateType = {
  passiveIncome_desiredMonthly: MINIMUM_WAGE_BR * 2,
  initialAmount: 0,
  yield_averageInvestment: 4, // 4%
  yield_rateType: RATE_TYPES.YEARLY,
};

const getInitialFormState = (): BaseFormStateType => {
  // checks if there's something stored in LS. if not, use the default
  const storedState = get<BaseFormStateType>(LS_CALC_STATE);

  if (storedState) {
    const storedKeys = Object.keys(storedState).sort().join();
    const expectedKeys = Object.keys(defaultBaseFormState).sort().join();
    if (storedKeys !== expectedKeys) {
      // Must clear whatever was stored, it's an older version...
      set(LS_CALC_STATE, defaultBaseFormState);
    } else {
      return storedState;
    }
  }

  return defaultBaseFormState;
};

type FormParams = {
  onFormChange: (newFormData: BaseFormStateType) => void;
};

export default function BaseForm({ onFormChange }: FormParams) {
  const [formData, setFormData] = useState(getInitialFormState());

  useEffect(() => {
    set(LS_CALC_STATE, formData);
    onFormChange(formData);
  }, [formData, onFormChange]);

  const handleDesiredMonthlyPassiveIncomeChange = useCallback(
    (value: number) => {
      setFormData({
        ...formData,
        passiveIncome_desiredMonthly: value,
      });
    },
    [formData]
  );

  const handleInitialAmountChange = useCallback(
    (value: number) => {
      setFormData({
        ...formData,
        initialAmount: value,
      });
    },
    [formData]
  );

  const handleAverageInvestmentYieldChange = useCallback(
    (value: number) => {
      setFormData({
        ...formData,
        yield_averageInvestment: value,
      });
    },
    [formData]
  );

  const handleAverageInvestmentYieldRateTypeChange = useCallback(
    (event: SelectChangeEvent<RATE_TYPES>) => {
      const newType = event.target.value as RATE_TYPES;
      let newYield_averageInvestment = formData.yield_averageInvestment;

      if (newType === RATE_TYPES.YEARLY) {
        // add logic to change the 'yield_averageInvestment' to become yearly
        newYield_averageInvestment = getAnnualInterestRate(
          formData.yield_averageInvestment
        );
      } else if (newType === RATE_TYPES.MONTHLY) {
        // add logic to change the 'yield_averageInvestment' to become monthly
        newYield_averageInvestment = getMonthlyInterestRate(
          formData.yield_averageInvestment
        );
      }

      setFormData({
        ...formData,
        yield_rateType: newType,
        yield_averageInvestment: newYield_averageInvestment,
      });
    },
    [formData]
  );

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} mt={-2}>
        <Typography variant="h5" align="center">
          Run a simulation!
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <label>Desired passive income</label>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <CurrencyTextField
            id="desired-passive-income-field"
            value={formData.passiveIncome_desiredMonthly}
            currencySymbol="$"
            minimumValue="0"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(_: any, value: number) => {
              handleDesiredMonthlyPassiveIncomeChange(value);
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <label>Current value to invest</label>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <CurrencyTextField
            id="initial-amount-field"
            value={formData.initialAmount}
            currencySymbol="$"
            minimumValue="0"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(_: any, value: number) => {
              handleInitialAmountChange(value);
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <label>Real interest rate</label>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CurrencyTextField
                id="average-investment-yield-field"
                value={formData.yield_averageInvestment}
                currencySymbol="%"
                minimumValue="0"
                decimalCharacter="."
                decimalPlaces={3}
                digitGroupSeparator=","
                onChange={(_: any, value: number) => {
                  handleAverageInvestmentYieldChange(value);
                }}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                variant="standard"
                id="demo-simple-select"
                value={formData.yield_rateType}
                onChange={handleAverageInvestmentYieldRateTypeChange}
                fullWidth
              >
                <MenuItem value={RATE_TYPES.YEARLY}>Yearly</MenuItem>
                <MenuItem value={RATE_TYPES.MONTHLY}>Monthly</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
