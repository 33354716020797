import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { get, set } from 'local-storage';

import { FormControl, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';

const LS_CALC_STATE = 'dgf_calc_state';

export type DateGoalFormStateType = {
  retirement_year: number;
};

const defaultDateGoalFormState: DateGoalFormStateType = {
  // With this we can calculate how far you are from actually retiring at the desired age
  retirement_year: new Date().getFullYear() + 20,
};

const getInitialFormState = (): DateGoalFormStateType => {
  // checks if there's something stored in LS. if not, use the default
  const storedState = get<DateGoalFormStateType>(LS_CALC_STATE);

  if (storedState) {
    const storedKeys = Object.keys(storedState).sort().join();
    const expectedKeys = Object.keys(defaultDateGoalFormState).sort().join();
    if (storedKeys !== expectedKeys) {
      // Must clear whatever was stored, it's an older version...
      set(LS_CALC_STATE, defaultDateGoalFormState);
    } else {
      return storedState;
    }
  }

  return defaultDateGoalFormState;
};

type FormParams = {
  onFormChange: (newFormData: DateGoalFormStateType) => void;
};

export default function DateGoalForm({ onFormChange }: FormParams) {
  const [formData, setFormData] = useState(getInitialFormState());

  useEffect(() => {
    set(LS_CALC_STATE, formData);
    onFormChange(formData);
  }, [formData, onFormChange]);

  const handleRetirementYearChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        retirement_year: +e.target.value,
      });
    },
    [formData]
  );

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <label>When would you like to achieve your financial freedom?</label>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              onChange={handleRetirementYearChange}
              value={formData.retirement_year}
              id="retirement-year"
              type="number"
              variant="standard"
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
